import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import jtagLogo from '../../assets/jtag_logo.svg'
import agrioLogo from '../../assets/agrio_logo.svg'
import MainContext from '../../context/MainContext'

// Icons
import Icon from '../Icon'
import { Menu as MenuIcon } from '@styled-icons/ionicons-sharp/Menu'
import { LogOut } from '@styled-icons/ionicons-sharp/LogOut'
import { PersonAdd } from '@styled-icons/ionicons-sharp/PersonAdd'

const Navbar = ({ menuItems }) => {

    const { authState, setCurrentUser, users, company, setShowSignup } = useContext(MainContext)

    const [isOpen, setIsOpen] = useState(false)

    const handleChangeUser = () => {
        const userIndex = users.findIndex(el => el._id === authState.userInfo._id)

        var newUserIndex = 0
        if (userIndex + 1 < users.length) {
            newUserIndex = userIndex + 1
        }

        const newUser = { ...users[newUserIndex] }
        setCurrentUser(newUser)

        localStorage.setItem('currentUser', newUser._id)
    }

    return (
        authState ?
            <Nav company={company}>
                <LogoContainer>
                    {window.location.hostname.includes('jtag') ?
                        <Logo src={jtagLogo}></Logo>
                        :
                        <Logo src={agrioLogo}></Logo>
                    }
                </LogoContainer>
                <Hamburger color="white" onClick={() => setIsOpen(prev => !prev)} />
                <Menu isOpen={isOpen}>
                    {menuItems.map((item, index) => {
                        return <MenuLink key={index} href={item.path}>{item.icon && <Icon color="white">{item.icon}</Icon>}{item.title}</MenuLink>
                    })}
                    {authState.userInfo &&
                        authState.userInfo.role === "admin" &&
                        <MenuLink onClick={() => setShowSignup(true)}><Icon color="white"><PersonAdd /></Icon></MenuLink>}
                    <MenuLink>
                        <Initials
                            color={authState.userInfo && authState.userInfo.color || 'grey'}
                        >
                            <span>
                                {authState.userInfo &&
                                    `${authState.userInfo.name.substring(0, 1)}${authState.userInfo.surname.substring(0, 1)}`}
                            </span>
                        </Initials>
                    </MenuLink>
                    <MenuLink href={'/logout'}>
                        <Icon href={'/logout'} color="white"><LogOut /></Icon>
                    </MenuLink>
                </Menu>
            </Nav>
            : <div></div>
    )
}

const Nav = styled.div`
    padding: 0 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: ${({ company }) => company === 'jtag' ? '#003355' : 'seagreen'};
`

const Menu = styled.div`
    display: none;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 768px) {
        display: flex;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        max-height: ${({ isOpen }) => (isOpen ? "100vh" : "0")};
        transition: max-height 0.3s ease-in;
    }

    @media (min-width: 768px) {
        display: flex;
        overflow: hidden;
        flex-direction: row;
    }
`

const MenuLink = styled.a`
    padding: 1rem 1rem;
    color: black;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s ease-in;
    font-size: 0.9rem;

    &:hover {
        color: #fff;
        text-decoration: none;
    }
`

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const Logo = styled.img`
    padding: 1rem 0;
    padding-left: 2rem;
    text-decoration: none;
    font-weight: 800;
    font-size: 1.7rem;
    height: 2rem;
    cursor: pointer;
`

const Hamburger = styled(MenuIcon)`
    font-size: 16px;
    width: 2rem;
    cursor: pointer;
    display: none;
    padding-right: 2rem;
    color: black;


    @media (max-width: 768px) {
        display: flex;
    }
`
const Toolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 2rem;


    @media (max-width: 768px) {
        width: 100%;
        overflow: hidden;
        padding: 0.5rem 0;
        justify-content: center;
        background-image: linear-gradient(to bottom, lightgrey, white);
    }
`
const Initials = styled.div`
    width:  2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ color }) => color ? color : 'lightgrey'};

    span {
        color: ${({ color }) => {
        // If a leading # is provided, remove it
        if (color.slice(0, 1) === '#') {
            color = color.slice(1);
        }

        // If a three-character hexcode, make six-character
        if (color.length === 3) {
            color = color.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }

        // Convert to RGB value
        var r = parseInt(color.substr(0, 2), 16);
        var g = parseInt(color.substr(2, 2), 16);
        var b = parseInt(color.substr(4, 2), 16);

        // Get YIQ ratio
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Check contrast
        return (yiq >= 128) ? 'black' : 'white';
    }}
    }

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`

export default Navbar;
