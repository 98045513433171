import React, { useContext, useEffect } from "react"
import { useCookies } from "react-cookie"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import api from "../../api"
import Navbar from "../../components/Navbar"
import MainContext from "../../context/MainContext"

const MainLayout = ({ menuItems }) => {

  const navigate = useNavigate()
  const [cookies] = useCookies([]);
  const { setIsAuthenticated, authState, setAuthState, sendMessageToHost } = useContext(MainContext)

  useEffect(() => {
    const retrieveAuthState = async () => {
      await api.getUser()
        .catch(res => {
          navigate('/logout')
        })
        .then(res => {
          setAuthState({
            ...authState,
            userInfo: res.data.userInfo
          })
          sendMessageToHost({ code: 102 })
        })
    }
    if (localStorage.getItem('loggedIn') === 'true') {
      setIsAuthenticated(true)
      retrieveAuthState()
    } else {
      navigate('/login')
    }
  }, [])

  return (
    <Background>
      <Navbar menuItems={menuItems} />
      <Body>
        {authState ? <Outlet /> : <h1>Loading...</h1>}
      </Body>
    </Background>
  )
}

const Background = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const Body = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-top: 1rem;
`

export default MainLayout