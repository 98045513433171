import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import '@atlaskit/css-reset'
import styled, { useTheme } from 'styled-components'
import Navbar from './components/Navbar'
import Button from './components/Button'
import Gantt from './views/Gantt'
import Home from './views/Home'
import { MainProvider } from './context/MainContext'
import Kanban from './views/Kanban'
import initialData from './initial-data'
import api from './api'
import Settings from './views/Settings'
import Login from './views/Login'
import Signup from './views/Signup'
import MainLayout from './layouts/MainLayout'
import Logout from './views/Logout'
import { TasksProvider } from './context/TasksContext'
import { OrdersProvider } from './context/OrdersContext'
import Order from './views/Order'
import { Cash } from '@styled-icons/ionicons-sharp/Cash'
import { Calendar } from '@styled-icons/ionicons-sharp/Calendar'

// Pallete
// primary: #170e27
// secondary: #f2fafc

// Connect to socket
const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL)

// Socket codes
// 101: new connection
// 102: database updated

// Send message to host
const sendMessageToHost = (message) => {
  socket.send(JSON.stringify({ message: message }))
}

// Connection opened
socket.addEventListener('open', function (event) {
  sendMessageToHost({ code: 101 })
});

socket.addEventListener('message', function (event) {
  const message = JSON.parse(event.data)

  if (message.code === 102) {
    // handlePrepareTasks()
    // console.log('code 102');
  }
})

const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authState, setAuthState] = useState()

  const [project, setProject] = useState('Ceratocone1')
  const [data, setData] = useState()
  const [orders, setOrders] = useState()
  const [projects, setProjects] = useState([])
  const [company, setCompany] = useState(window.location.hostname.includes('agrio') ? 'agrio' : 'jtag')
  const [users, setUsers] = useState()
  const [showSignup, setShowSignup] = useState(false)

  const menuItems =
    [
      { path: "/", icon: <Calendar /> },
      { path: "/compras", icon: <Cash /> },
    ]

  const handleGetProjects = async () => {
    await api.getProjects()
      .then(res => setProjects(res.data.data))
  }

  useEffect(() => {
    handleGetProjects()
  }, [])

  return (
    <MainProvider value={{
      isAuthenticated,
      setIsAuthenticated: isAuthenticated => setIsAuthenticated(isAuthenticated),
      authState,
      setAuthState: authState => setAuthState(authState),
      project,
      setProject: project => setProject(project),
      projects,
      setProjects: projects => setProject(projects),
      socket,
      sendMessageToHost: sendMessageToHost,
      company: company,
      setCompany: company => setCompany(company),
      users: users,
      setUsers: users => setUsers(users),
      showSignup: showSignup,
      setShowSignup: showSignup => setShowSignup(showSignup),
    }}>
      <Background>
        <Router>
          <Routes>
            <Route path='/' element={<MainLayout menuItems={menuItems} />}>
              <Route exact path="/" element={
                <TasksProvider value={{
                  data: data,
                  setData: data => setData(data)
                }}>
                  <Kanban />
                </TasksProvider>
              } />
              <Route exact path="/compras" element={
                <OrdersProvider value={{
                  data: orders,
                  setData: data => setOrders(data)
                }}>
                  <Order />
                </OrdersProvider>
              } />
              <Route exact path="/settings" element={<Settings />} />
            </Route>
            <Route exact path="/login" element={<Login />} />
            {window.location.hostname === "192.168.15.102" && <Route exact path="/signup" element={<Signup />} />}
            <Route exact path="/logout" element={<Logout />} />
            <Route path='*' element={<Navigate to='/login' />} />
          </Routes>
        </Router>
      </Background>
    </MainProvider>
  )
}

const Background = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const Body = styled.div`
  overflow-y: auto;
  height: 100%;
`

export default App;
