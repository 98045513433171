import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import KanbanTask from "../Task"
import { Droppable } from "react-beautiful-dnd"
import Button from "../../Button"
import MainContext from "../../../context/MainContext"
import api from "../../../api"
import Icon from "../../Icon"
import { Eye } from '@styled-icons/ionicons-sharp/Eye'
import { EyeOff } from '@styled-icons/ionicons-sharp/EyeOff'
import TasksContext from "../../../context/TasksContext"


const KanbanColumn = ({ data, column, tasks, index, showAddButton = false, isReadOnly = false }) => {

    const { authState, projects, sendMessageToHost } = useContext(MainContext)
    
    const [showTasks, setShowTasks] = useState(column.title !== "Closed")
    const showHideButton = column.title === "Closed"

    const handleAddNewTask = async () => {

        const newTask = {
            start: Date.now().toString(),
            end: undefined,
            assignedTo: [authState.userInfo._id],
            title: "New task",
            type: "task",
            project: projects[0]._id,
            priority: "moderate",
            status: column._id,
        }

        await api.createTask(newTask)
            .then(res => {
                const newTaskId = res.data.id

                const newTasksIds = [...data.columns[column.id].tasksIds]
                newTasksIds.unshift(newTaskId)

                return newTasksIds
            })
            .then(res => {
                const newColumn = {
                    ...data.columns[column.id],
                    tasksIds: res,
                }
                console.log('newColumn', newColumn);

                handleUpdateColumn(newColumn)
            })
    }

    const handleUpdateColumn = async (column) => {
        await api.updateColumn(column._id, column)
            .then(res => sendMessageToHost({ code: 102 }))
    }

    return (
        <Container>
            <Header>
                <Title><span>{column.title}</span></Title>
                {showAddButton && <Button round color='darkgrey' textColor={'black'} onClick={handleAddNewTask}>+</Button>}
                {showHideButton &&
                    <Button
                        round
                        color='transparent'
                        textColor={'black'}
                        onClick={() => setShowTasks(prev => !prev)}
                    >
                        <Icon>{showTasks ?<EyeOff /> : <Eye />}</Icon>
                    </Button>}
            </Header>
            <Droppable droppableId={column.id}>
                {(provided, snapshot) => (
                    <TaskList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                        showHideButton={showHideButton}
                    >
                        {showTasks && <InnerList data={data} tasks={tasks} isReadOnly={isReadOnly} />}
                        {provided.placeholder}
                    </TaskList>
                )}
            </Droppable>
        </Container>
    )
}

class InnerList extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.tasks === this.props.tasks) {
            return false
        }
        return true
    }

    render() {
        return this.props.tasks.map((task, index) => {
            return <KanbanTask key={task.id} data={this.props.data} task={task} index={index} isReadOnly={this.props.isReadOnly} />
        })
    }
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(220, 220, 220);
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.25rem;
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`
const Title = styled.div`
    /* margin: 1rem 0rem; */

    span {
        font-size: 1.4rem;
        font-weight: 400;
    }
`
const TaskList = styled.div`
    flex-grow: 1;
    ${({ isDraggingOver }) => isDraggingOver && `background-color: lightblue;`}
    min-height: 2rem;

    ${({showHideButton}) => showHideButton && `opacity: 0.5;`}
`

export default KanbanColumn