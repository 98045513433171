import React, { createContext } from 'react'

const TasksContext = createContext(
    {
        data: {},
        setData: function (data) {
            this.data = data
        },
    }
)

export const TasksProvider = ({children, value}) => {

    return (
        <TasksContext.Provider value={value}>
            <TasksContext.Consumer>
                {(consumer) => {
                    return (
                        children
                    )
                }}
            </TasksContext.Consumer>
        </TasksContext.Provider>
    )
}

export default TasksContext