import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import KanbanColumn from "../../components/Kanban/Column"
import { DragDropContext } from 'react-beautiful-dnd'
import api from "../../api"
import MainContext from "../../context/MainContext"
import Backdrop from "../../components/Backdrop"
import SignupForm from "../../partials/SignupForm"
import OrdersContext from "../../context/OrdersContext"
import OrderColumn from "../../components/Order/Column"

const Order = () => {

    const { authState, setAuthState, socket, sendMessageToHost, projects, setUsers, showSignup, setShowSignup } = useContext(MainContext)
    const { data, setData} = useContext(OrdersContext)
    const [columnOrder, setColumnOrder] = useState(['6247009033b6d3520d47e4ab', '624706ba33b6d3520d47e4ad'])
    
    const [allColumns, setAllColumns] = useState()

    const company = window.location.hostname === 'kanban.agrio.tec.br' ? 'agrio' : 'jtag'

    useEffect(() => {
        const updateHook = (event) => {
            const message = JSON.parse(event.data)

            if (message.code == 102) {
                handleGetColumns()
            }
        }

        socket.addEventListener('message', updateHook)

        return () => {
            socket.removeEventListener('message', updateHook)
        }
    }, [])

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result

        // Not to do if it has no destination
        if (!destination) {
            return
        }

        // Check if destination !== source and if the cards were reordered
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        // Retrieve source column from data
        const sourceColumn = data.columns[source.droppableId]
        // Retrieve destination column from data
        const destinationColumn = data.columns[destination.droppableId]

        // Source and destination are the same
        if (sourceColumn === destinationColumn) {
            // Retrieve tasksIds of the source column
            const newTasksIds = Array.from(sourceColumn.tasksIds)
            // Delete the moved task id from source column
            newTasksIds.splice(source.index, 1)
            // Add the moved task id to destination column
            newTasksIds.splice(destination.index, 0, draggableId)

            // Store new tasksIds in a new column
            const newColumn = {
                ...sourceColumn,
                tasksIds: newTasksIds
            }

            // Replace the new column in the data
            const newData = {
                ...data,
                columns: {
                    ...data.columns,
                    [newColumn.id]: newColumn
                }
            }

            // Update data
            setData(newData)
            console.log(draggableId);

            handleUpdateColumn(newColumn)

            return
        }

        // Moving task among columns
        if (sourceColumn !== destinationColumn) {
            // Retrieve tasksIds of the source column
            const sourceTasksIds = Array.from(sourceColumn.tasksIds)

            // Delete the moved task id from source column
            sourceTasksIds.splice(source.index, 1)

            // Store new tasksIds in a new column
            const newSourceColumn = {
                ...sourceColumn,
                tasksIds: sourceTasksIds
            }

            // Retrieve tasksIds of the source column
            const destinationTasksIds = Array.from(destinationColumn.tasksIds)
            // Add the moved task id to destination column
            destinationTasksIds.splice(destination.index, 0, draggableId)

            // Store new tasksIds in a new column
            const newDestinationColumn = {
                ...destinationColumn,
                tasksIds: destinationTasksIds
            }

            // Replace the new columns in the data
            const newData = {
                ...data,
                columns: {
                    ...data.columns,
                    [newSourceColumn.id]: newSourceColumn,
                    [newDestinationColumn.id]: newDestinationColumn,
                }
            }

            // Update data
            setData(newData)

            var movedTask = data.tasks.find(el => el.id === draggableId)
            movedTask = {
                ...movedTask,
                status: destinationColumn.id,
            }
            handleUpdateTask(movedTask)
            handleUpdateColumn(newSourceColumn)
            handleUpdateColumn(newDestinationColumn)

            return
        }
    }

    const handleGetColumns = async () => {
        const _handle = async (userInfo) => {
            await api.getOrderColumns()
                .then(res => {
                    var _columns = {}

                    res.data.columns.forEach((col) => {
                        _columns = {
                            ..._columns,
                            [col.id]: col
                        }
                    })

                    return _columns
                })
                .then((columns) => {
                    setColumnOrder(Object.keys(columns))
                    handlePrepareTasks(columns)
                })
        }

        // TODO: it is ugly!
        if (!authState.userInfo) {
            await api.getUser()
                .then(res => _handle(res.data.userInfo))
        }
        else {
            _handle(authState.userInfo)
        }
    }

    const handlePrepareTasks = async (columns) => {
        try {
            await api.getOrders()
                .then(res => {
                    // Retrieve tasks from database
                    const tasks = res.data.data
                    tasks.forEach((task, index) => {
                        tasks.splice(index, 1, {
                            ...task,
                            id: task._id
                        })
                    });

                    api.getUser()
                        .then(res => {
                            const newData = {
                                tasks: tasks,
                                columns,
                                columnOrder: res.data.userInfo.columnOrder,
                            }
                            setData(newData)
                        })
                })
        } catch (error) {
            api.getUser()
                .then(res => {
                    const newData = {
                        columns,
                        columnOrder: res.data.userInfo.columnOrder,
                    }
                    setData(newData)
                })
        }
    }

    const handleUpdateTask = async (task) => {
        await api.updateOrder(task.id, task)
            .then(res => sendMessageToHost({ code: 102 }))
    }

    const handleUpdateColumn = async (column) => {
        await api.updateOrderColumn(column._id, column)
            .then(res => sendMessageToHost({ code: 102 }))
    }

    useEffect(() => {
        handleGetColumns()
    }, [])

    const handleGetUsers = async () => {
        await api.getUsers()
            .then(res => setUsers(res.data.data))
    }

    useEffect(() => {
        handleGetUsers()
    }, [])

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Container>
                    {data && data.columns && columnOrder.map((columnId, index) => {
                        const column = data.columns[columnId]
                        const tasks = column.tasksIds.map(taskId => data.tasks.find(el => el.id === taskId))

                        return <OrderColumn key={column._id} data={data} column={column} tasks={tasks} index={index} showAddButton={index === 0} />
                    })}
                </Container>
            </DragDropContext>
            <Backdrop show={showSignup} onClick={() => setShowSignup(false)}><SignupForm /></Backdrop>
        </>
    )
}

const Wrapper = styled.div`
    background-color: ${({ color }) => color ? `${color}66` : 'grey'};
    padding: 3rem 0rem;

`
const Container = styled.div`
    display: flex;
    flex-direction: row;
`
const Title = styled.div`
    margin: 0rem 1rem;
    font-size: 1.5rem;
`

export default Order