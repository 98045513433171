import React, { createContext } from 'react'

const OrdersContext = createContext(
    {
        data: {},
        setData: function (data) {
            this.data = data
        },
    }
)

export const OrdersProvider = ({children, value}) => {

    return (
        <OrdersContext.Provider value={value}>
            <OrdersContext.Consumer>
                {(consumer) => {
                    return (
                        children
                    )
                }}
            </OrdersContext.Consumer>
        </OrdersContext.Provider>
    )
}

export default OrdersContext