import React, { createContext } from 'react'

const MainContext = createContext(
    {
        data: {},
        setData: function (data) {
            this.data = data
        },
        project: 'JTAG/Ceratocone',
        setProject: function (project) {
            this.project = project
        },
        currentUser: [],
        setCurrentUser: function (currentUser) {
            this.currentUser = currentUser
        },
        users: [],
        setUsers: function (users) {
            this.users = users
        },
        projects: [],
        setProjects: function (projects) {
            this.projects = projects
        },
        socket: undefined,
        sendMessageToHost: undefined,
        isAuthenticated: {},
        setIsAuthenticated: function (isAuthenticated) {
            this.isAuthenticated = isAuthenticated
        },
        authState: {},
        setAuthState: function (authState) {
            this.authState = authState
        },
        company: undefined,
        setCompany: function (company) {
            this.company = company
        },
    }
)

export const MainProvider = ({children, value}) => {

    return (
        <MainContext.Provider value={value}>
            <MainContext.Consumer>
                {(consumer) => {
                    return (
                        children
                    )
                }}
            </MainContext.Consumer>
        </MainContext.Provider>
    )
}

export default MainContext