import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import wallpaper from '../../assets/login_wallpaper.jpg'
import wallpaperAgrio from '../../assets/login_agrio_wallpaper.jpg'
import logo from '../../assets/jtag_logo_black.svg'
import logoAgrio from '../../assets/agrio_logo.svg'
import Button from "../../components/Button"
import api from "../../api"
import { useNavigate } from "react-router-dom"
import MainContext from "../../context/MainContext"
import { useCookies } from "react-cookie"


const Login = () => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies([]);

    const { users, setUsers, setIsAuthenticated, authState, setAuthState, company } = useContext(MainContext)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleSignIn = async (event) => {
        const credentials = {
            username,
            password,
        }

        await api.signin(credentials)
            .catch(err => console.log(err))
            .then(res => {
                const _authState = {
                    token: res.data.token,
                    userInfo: res.data.userInfo,
                    expiresAt: res.data.expiresAt,
                }
                setAuthState(_authState)
                setIsAuthenticated(true)
                localStorage.setItem('loggedIn', 'true')
                setCookie('loggedIn', true, { path: '/', maxAge: 600 })
                navigate('/')
            })
            // .then(() => {
            //     retrieveData()
            // })
    }

    // const retrieveData = async () => {
    //     await api.getUsers()
    //         .then(res => setUsers(res.data.data))
    // }

    // useEffect(() => {
    //     if (
    //         authState &&
    //         localStorage.getItem('loggedIn') === 'true' &&
    //         users
    //     )
    //         navigate('/')
    // })

    return (
        <>
            {/* {isAuthenticated && <Navigate to="/" />} */}
            <Container>
                <ImageWrapper src={company === "jtag" ? wallpaper : wallpaperAgrio}>
                    <Wallpaper />
                </ImageWrapper>
                <LoginWrapper>
                    <Card>
                        <Logo src={company === "jtag" ? logo : logoAgrio} />
                        <TextInput type="text" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)} />
                        <TextInput type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                        <Button color={company === "jtag" ? 'darkblue' : 'seagreen'} onClick={handleSignIn}>Login</Button>
                    </Card>
                </LoginWrapper>
            </Container>
        </>

    )
}

const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: lightgrey;
    overflow-y: hidden;
`
const LoginWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const ImageWrapper = styled.div`
    display: flex;
    flex: 2;

    background-color: #668499;
    background-image: ${({ src }) => `url(${src});`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media only screen and (max-width: 768px) {
        flex: 0;
    }
`
const Wallpaper = styled.div`
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
`
const Logo = styled.img`
    text-decoration: none;
    height: 5.5rem;
    margin: 2rem;
`
const Card = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    background-color: white;
    border-radius: 1rem;
    margin: 2rem;

    @media only screen and (max-width: 768px) {
        margin: 0;
        padding: 1rem;
    }
`
const TextInput = styled.input`
    margin: 1rem;
    padding: 0.75rem;
    display: flex;
    flex-grow: 1;
    font-size: 1.1rem;
    font-weight: 300;
    outline: none;

    border: none;
    border-bottom: 1px solid lightgray;
`

export default Login