import React, { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Draggable } from "react-beautiful-dnd"
import { Column, Row } from "../../Grid"
import Button from '../../Button'
import MainContext from "../../../context/MainContext"
import api, { deleteTask } from "../../../api"

import { StyledIconBase } from '@styled-icons/styled-icon'
import { Trash } from '@styled-icons/ionicons-sharp/Trash'
import { Add } from '@styled-icons/ionicons-sharp/Add'
import { Close } from '@styled-icons/ionicons-sharp/Close'
import TasksContext from "../../../context/TasksContext"


const KanbanTask = ({ data, task, index, isReadOnly }) => {

    const { users, projects, authState, sendMessageToHost } = useContext(MainContext)

    const assignedToUser = users && task.assignedTo ? users.find(el => task.assignedTo.includes(el._id)) : undefined

    const [title, setTitle] = useState(task.title)
    const [showSubtasksDeleteButton, setShowSubtasksDeleteButton] = useState(false)
    const [showAddAssignedTo, setShowAddAssignedTo] = useState(false)

    const handleUpdateTask = async (task) => {
        await api.updateTask(task.id, task)
            .then(res => sendMessageToHost({ code: 102 }))
    }

    const handlePriorityChange = (task) => {
        const currentPriority = task.priority
        var newPriority = undefined

        switch (currentPriority) {
            case 'low':
                newPriority = 'moderate'
                break;
            case 'moderate':
                newPriority = 'high'
                break;
            case 'high':
                newPriority = 'low'
                break;
            default:
                break;
        }

        const newTask = {
            ...task,
            priority: newPriority,
        }

        handleUpdateTask(newTask)
    }

    const handleSubtaskCheckChanged = (subtask, index) => {
        const newSubtask = {
            ...subtask,
            done: !subtask.done,
        }

        const newSubtasks = [...task.subtasks]
        newSubtasks.splice(index, 1)
        newSubtasks.splice(index, 0, newSubtask)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)
    }

    const handleSubtaskBlur = (event, index) => {
        const newTitle = event.target.value

        const newSubtasks = [...task.subtasks]
        const newSubtask = {
            ...newSubtasks[index],
            title: newTitle,
        }

        newSubtasks.splice(index, 1, newSubtask)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)

        setShowSubtasksDeleteButton(false)
    }

    const handleDeleteSubtask = (index) => {
        const newSubtasks = [...task.subtasks]

        newSubtasks.splice(index, 1)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)

        setShowSubtasksDeleteButton(false)
    }

    const handleAddSubtask = () => {
        const newSubtasks = [...task.subtasks]
        const newSubtask = {
            title: "",
            done: false
        }

        newSubtasks.push(newSubtask)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)
    }

    const handleTitleChange = (event) => {
        const newTitle = event.target.value
        setTitle(newTitle)
    }

    const handleTitleBlur = (event) => {
        const newTitle = event.target.value
        setTitle(newTitle)

        const newTask = {
            ...task,
            title: newTitle,
        }

        handleUpdateTask(newTask)
    }

    const handleDeleteTask = async (task) => {

        await api.deleteTask(task.id, task)
            .then(res => {
                const deletedTaskId = res.data.id
                const newTasksIds = [...data.columns[task.status].tasksIds]
                const deletedTaskIndex = newTasksIds.findIndex(el => el === deletedTaskId)
                newTasksIds.splice(deletedTaskIndex, 1)

                return newTasksIds
            })
            .then(res => {
                const newColumn = {
                    ...data.columns[task.status],
                    tasksIds: res,
                }

                handleUpdateColumn(newColumn)
            })
    }

    const handleUpdateColumn = async (column) => {
        await api.updateColumn(column._id, column)
            .then(res => sendMessageToHost({ code: 102 }))
    }

    const populateSubtasks = () => {
        const subtasks = [...task.subtasks]

        subtasks.forEach((subtask, index) => {
            document.getElementById('subtask-' + (isReadOnly ? 't' : 'f') + task.id + '-' + index).value = subtask.title
        });
    }

    useEffect(() => {
        populateSubtasks()
    })

    const handleAssignedToChanged = (id, index) => {
        const userIndex = users.findIndex(el => el._id === id)

        var newUserIndex = 0
        if (userIndex + 1 < users.length) {
            newUserIndex = userIndex + 1
        }

        const newUser = users[newUserIndex]._id
        const newAssignedTo = [...task.assignedTo]
        newAssignedTo.splice(index, 0)
        newAssignedTo.splice(index, 1, newUser)

        const newTask = {
            ...task,
            assignedTo: newAssignedTo
        }

        handleUpdateTask(newTask)
    }

    const handleAddAssignedTo = () => {
        const newAssignedTo = [...task.assignedTo]

        if (task.assignedTo.length < users.length) {
            users.every(user => {
                if (!task.assignedTo.includes(user._id)) {
                    newAssignedTo.push(user._id)
                    return false
                }
                return true
            });

            const newTask = {
                ...task,
                assignedTo: newAssignedTo
            }

            handleUpdateTask(newTask)
        }
    }

    const handleProjectChanged = () => {
        const projectIndex = projects.findIndex(el => el._id === task.project)

        var newProjectIndex = 0
        if (projectIndex + 1 < projects.length) {
            newProjectIndex = projectIndex + 1
        }

        const newProject = projects[newProjectIndex]._id

        const newTask = {
            ...task,
            project: newProject
        }

        handleUpdateTask(newTask)
    }

    return (
        <Draggable draggableId={task.id} index={index} isDragDisabled={authState.userInfo._id !== task.assignedTo[0]}>
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    <Row>
                        <Column noPadding sm={12} md={12} lg={12}>
                            <Header color={projects && projects.find(el => el._id === task.project).color}>
                                <span onClick={handleProjectChanged}>{projects && projects.find(el => el._id === task.project).title}</span>
                                <AcitonButtonsContainer>
                                    <Icon onClick={handleAddSubtask}><Add color='white' /></Icon>
                                    <Icon onClick={() => { handleDeleteTask(task) }}><Trash color='white' /></Icon>
                                </AcitonButtonsContainer>
                            </Header>
                        </Column>
                        <Column flex sm={12} md={12} lg={12}>
                            <Title value={title} onChange={handleTitleChange} onBlur={handleTitleBlur}></Title>
                        </Column>
                        <Column sm={12} md={12} lg={12}>
                            <SubTasksContainer>
                                {task.subtasks.map((subtask, index) => {
                                    return (
                                        <SubTask key={index}>
                                            <SubTaskCheckbox
                                                type='checkbox'
                                                checked={subtask.done}
                                                onChange={() => handleSubtaskCheckChanged(subtask, index)}
                                            />
                                            <SubTaskText
                                                id={'subtask-' + (isReadOnly ? 't' : 'f') + task.id + '-' + index}
                                                onFocus={(event) => {
                                                    event.target.value = subtask.title
                                                    setShowSubtasksDeleteButton(true)
                                                }}
                                                onBlur={(event) => handleSubtaskBlur(event, index)}
                                            />
                                            <Icon hidden={!showSubtasksDeleteButton} onClick={() => handleDeleteSubtask(index)}><Close /></Icon>
                                        </SubTask>
                                    )
                                })}
                            </SubTasksContainer>
                        </Column>
                        <Column sm={12} md={12} lg={12}>
                            <SubContainer>
                                <InitialsContainer>
                                    <Initials
                                        key={index}
                                        color={assignedToUser ? assignedToUser.color : 'grey'}
                                    >
                                        <span>{assignedToUser && `${assignedToUser.name.substring(0, 1)}${assignedToUser.surname.substring(0, 1)}`}</span>
                                    </Initials>
                                    {/* <Icon onClick={handleAddAssignedTo}><Add /></Icon> */}
                                </InitialsContainer>
                                <Button color='transparent' textColor={task.priority && (
                                    task.priority === 'high' ? 'red' :
                                        task.priority === 'moderate' ? 'orange' :
                                            task.priority === 'low' ? 'green' : 'gray'
                                )}
                                    onClick={() => handlePriorityChange(task)}
                                >
                                    {task.priority}
                                </Button>
                            </SubContainer>
                        </Column>
                    </Row>
                </Container>
            )}
        </Draggable>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    /* padding: 1rem; */
    border-radius: 0.25rem;

    background-color: ${({ isDragging }) => isDragging ? 'lightsteelblue' : 'white'};
`
const Icon = styled.div`
    width:  1.25rem;
    height: 1.25rem;
    margin-left: 1rem;

    ${StyledIconBase} {
        :hover {
            opacity: 0.7;
        }
    }

    ${({ hidden }) => hidden && `display: none;`}
`
const Header = styled.div`
    padding: 0.5rem 1rem;
    background-color: ${({ color }) => color ? color : 'grey'};

    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 0.9rem;
        font-weight: 400;
        color: lightgrey;
        cursor: pointer;
        text-transform: uppercase;
    }
`
const Title = styled.input`
    margin: 0.65rem;
    display: flex;
    flex-grow: 1;
    font-size: 1.1rem;
    font-weight: 300;
    outline: none;

    border: 0rem solid #000;
`
const TaskList = styled.div`

`
const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0.5rem;
`
const InitialsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 3;

    ${Icon} {
        display: none;
    }

    :hover {
        ${Icon} {
            display: block;
        }
    }
`
const Initials = styled.div`
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ color }) => color ? color : 'lightgrey'};

    span {
        color: ${({ color }) => {
            // If a leading # is provided, remove it
            if (color.slice(0, 1) === '#') {
                color = color.slice(1);
            }

            // If a three-character hexcode, make six-character
            if (color.length === 3) {
                color = color.split('').map(function (hex) {
                    return hex + hex;
                }).join('');
            }

            // Convert to RGB value
            var r = parseInt(color.substr(0, 2), 16);
            var g = parseInt(color.substr(2, 2), 16);
            var b = parseInt(color.substr(4, 2), 16);

            // Get YIQ ratio
            var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

            // Check contrast
            return (yiq >= 128) ? 'black' : 'white';
        }}
    }

    /* cursor: pointer; */
`
const SubTasksContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SubTask = styled.div`
    margin: 0rem 1rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const SubTaskCheckbox = styled.input`
    width:  1.3rem;
    height: 1.3rem;
`
const SubTaskText = styled(Title)`
    margin-left: 0.25rem;
    font-size: 0.9rem;
    margin: 0.5rem 0rem;
`
const AcitonButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export default KanbanTask