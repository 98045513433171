import React from "react";
import styled, { css } from "styled-components";

const Icon = styled.div`
    font-size: 16px;
    width: 1.5rem;
    height: 1.5rem;
    color: ${({ color }) => color ? color : 'black'};
    margin: 0rem 1rem;
    padding: 0.25rem;

    ${({ filled, fillColor }) => filled && fillColor && css`
        background-color: ${fillColor} ;
        border-radius: 100%;
    `}

    :hover {
        opacity: 0.9;
    }
`
export default Icon;