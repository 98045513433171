import axios from 'axios'

const publicFetch = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

// --- Login
export const signin = (payload) => publicFetch.post(`/signin`, payload)
export const signup = (payload) => publicFetch.post(`/signup`, payload)

// --- Projects
export const createProject = (payload) => publicFetch.post(`/project`, payload)
export const getProjects = () => publicFetch.get(`/projects`)

// --- Tasks
export const createTask = (payload) => publicFetch.post(`/task`, payload)
export const getTasks = () => publicFetch.get(`/tasks`)
export const updateTask = (id, payload) => publicFetch.put(`/task/${id}`, payload)
export const deleteTask = (id, payload) => publicFetch.put(`/task/delete/${id}`, payload)

// --- Users
export const createUser = (payload) => publicFetch.post(`/user`, payload)
export const getUser = () => publicFetch.get(`/user`)
export const getUsers = () => publicFetch.get(`/users`)

// --- Columns
export const getColumnsByAssignedTo = (id) => publicFetch.put(`/columns/${id}`)
export const getColumns = () => publicFetch.get(`/columns`)
export const updateColumn = (id, payload) => publicFetch.put(`/column/${id}`, payload)

// --- Orders
export const createOrder = (payload) => publicFetch.post(`/order`, payload)
export const getOrders = () => publicFetch.get(`/orders`)
export const updateOrder = (id, payload) => publicFetch.put(`/order/${id}`, payload)
export const deleteOrder = (id, payload) => publicFetch.put(`/order/delete/${id}`, payload)

// --- Order Columns
export const getOrderColumns = () => publicFetch.get(`/ordercolumns`)
export const updateOrderColumn = (id, payload) => publicFetch.put(`/ordercolumn/${id}`, payload)


const api = {
    signin,
    signup,
    createProject,
    getProjects,
    getTasks,
    createTask,
    updateTask,
    deleteTask,
    createUser,
    getUser,
    getUsers,
    getColumnsByAssignedTo,
    getColumns,
    updateColumn,
    getOrders,
    createOrder,
    updateOrder,
    deleteOrder,
    getOrderColumns,
    updateOrderColumn,
}

export default api