import React, { useContext, useState } from "react"
import styled from "styled-components"
import { CirclePicker } from 'react-color'
import Button from "../../components/Button"
import api from "../../api"
import MainContext from "../../context/MainContext"

const Settings = () => {

    const { users, projects } = useContext(MainContext)

    const [name, setName] = useState()
    const [surname, setSurname] = useState()
    const [username, setUsername] = useState()
    const [initials, setInitials] = useState()
    const [color, setColor] = useState()

    const [projectName, setProjectName] = useState()
    const [projectColor, setProjectColor] = useState()

    const handleAddNewUser = async () => {
        const newUser = {
            name: name,
            surname: surname,
            username: username,
            initials: initials,
            color: color,
        }

        await api.createUser(newUser)
            .then(res => console.log(res))
    }

    const handleNewProject = async () => {
        const newProject = {
            title: projectName,
            color: projectColor,
        }

        await api.createProject(newProject)
            .then(res => console.log(res))
    }

    return (
        <Container>
            <NewUserContainer>
                <Title>Novo usuário</Title>
                <Input placeholder="Nome" value={name} onChange={(event) => setName(event.target.value)}></Input>
                <Input placeholder="Sobrenome" value={surname} onChange={(event) => setSurname(event.target.value)}></Input>
                <Input placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)}></Input>
                <Input placeholder="Iniciais" value={initials} maxLength={2} uppercase onChange={(event) => setInitials(event.target.value)}></Input>
                <CirclePicker onChange={(color) => { setColor(color.hex); console.log(color.hex); }} />
                <Initials color={color}>{initials}</Initials>
                <ButtonContainer>
                    <Button onClick={handleAddNewUser}>Adicionar</Button>
                </ButtonContainer>
                <Title>Usuário atuais</Title>
                <InitialsContainer>
                    {users && users.map((user, index) => {
                        return <Initials key={index} color={user.color}>{user.initials}</Initials>
                    })}
                </InitialsContainer>
            </NewUserContainer>
            <NewUserContainer>
                <Title>Novo projeto</Title>
                <Input placeholder="Nome do projeto" value={projectName} onChange={(event) => setProjectName(event.target.value)}></Input>
                <CirclePicker onChange={(color) => { setProjectColor(color.hex) }} />
                <Projects color={projectColor}>{projectName ? projectName : 'Novo projeto'}</Projects>
                <ButtonContainer>
                    <Button onClick={handleNewProject}>Adicionar</Button>
                </ButtonContainer>
                <Title>Projetos atuais</Title>
                <InitialsContainer>
                    {projects && projects.map((project, index) => {
                        return <Projects key={index} color={project.color}>{project.title}</Projects>
                    })}
                </InitialsContainer>
            </NewUserContainer>
        </Container>)
}

const Container = styled.div`
    margin: 1rem;
    justify-content: space-around;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
`
const NewUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: lightgrey;
    border-radius: 0.5rem;
`
const Input = styled.input`
    margin: 0.65rem 3rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-grow: 1;
    font-size: 1.1rem;
    font-weight: 300;
    outline: none;
    height: 2rem;
    background-color: transparent;
    min-width: 15rem;

    border: 0rem solid #000;
    border-bottom: 1px solid grey;
    /* text-transform: capitalize; */
    
    ${({ uppercase, value }) => uppercase && value !== null && `text-transform: uppercase;`}
`
const ButtonContainer = styled.div`
    margin: 1rem;
`
const Initials = styled.div`
    width:  2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    background-color: ${({ color }) => color ? color : 'grey'};

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`
const InitialsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const Title = styled.div`
    margin: 1rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
`
const Projects = styled.div`
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0.25rem;
    padding: 0.25rem 1rem;
    min-height: 1.5rem;
    min-width: 2rem;

    background-color: ${({ color }) => color ? color : 'grey'};

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`

export default Settings