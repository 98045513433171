import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        document.cookie = "loggedIn=false;"
        localStorage.setItem('loggedIn', 'false')
        navigate('/login')
    }, [])

    return <h1>Logging out...</h1>
}

export default Logout