import styled, { css } from 'styled-components'

export const Container = styled.div`
    max-width: 1360px;
    padding: 0 2rem;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }

    @media only screen and (max-width: 768px) {
        padding: 0 0;
    }
`

export const Row = styled.div`
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
    @media only screen and (max-width: 768px) {
        ${({ sm }) => sm && getWidthGrid(sm)}
    }
    @media only screen and (min-width: 768px) {
        ${({ md }) => md && getWidthGrid(md)}
    }
    @media only screen and (min-width: 1000px) {
        ${({ lg }) => lg && getWidthGrid(lg)}
    }
    
    ${({ flex }) => flex && `display: flex;`}
    ${({ center }) => center && `justify-content: center; `}

`

function getWidthGrid(value) {
    if (!value) return;

    let width = value / 12 * 100;
    return `width: ${width}%`
}

export const Column = styled.div`
    float: left;
    padding: .25rem;
    min-height: 1px;
    box-sizing: border-box;
    align-items: center;

    @media only screen and (max-width: 768px) {
        ${({ sm }) => sm && getWidthGrid(sm)}
    }
    @media only screen and (min-width: 768px) {
        ${({ md }) => md && getWidthGrid(md)}
    }
    @media only screen and (min-width: 1000px) {
        ${({ lg }) => lg && getWidthGrid(lg)}
    }

    ${({ flex }) => flex && css`display: flex;`}

    ${({ center }) => center && css`justify-content: center;`}
    ${({ right }) => right && css`justify-content: flex-end;`}

    ${({ noPadding }) => noPadding && css`padding: 0rem;`}
`
