import React, { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Draggable } from "react-beautiful-dnd"
import { Column, Row } from "../../Grid"
import Button from '../../Button'
import MainContext from "../../../context/MainContext"
import api, { deleteTask } from "../../../api"

import { StyledIconBase } from '@styled-icons/styled-icon'
import { Trash } from '@styled-icons/ionicons-sharp/Trash'
import { Add } from '@styled-icons/ionicons-sharp/Add'
import { Close } from '@styled-icons/ionicons-sharp/Close'


const OrderTask = ({ data, task, index, isReadOnly }) => {

    const { users, projects, authState, sendMessageToHost } = useContext(MainContext)

    const assignedToUser = users && task.assignedTo ? users.find(el => task.assignedTo.includes(el._id)) : undefined

    const [title, setTitle] = useState(task.title)
    const [company, setCompany] = useState(task.company)
    const [showSubtasksDeleteButton, setShowSubtasksDeleteButton] = useState(false)
    const [showAddAssignedTo, setShowAddAssignedTo] = useState(false)

    const handleUpdateTask = async (task) => {
        await api.updateOrder(task.id, task)
            .then(res => sendMessageToHost({ code: 102 }))
    }

    const handleAgencyChange = (task) => {
        const currentAgency = task.agency
        var newAgency = undefined

        switch (currentAgency) {
            case 'FAPESP':
                newAgency = 'FAFQ'
                break;
            case 'FAFQ':
                newAgency = 'NONE'
                break;
            case 'NONE':
                newAgency = 'FAPESP'
                break;
            default:
                break;
        }

        const newTask = {
            ...task,
            agency: newAgency,
        }

        handleUpdateTask(newTask)
    }

    const handleSubtaskCheckChanged = (subtask, index) => {
        const newSubtask = {
            ...subtask,
            done: !subtask.done,
        }

        const newSubtasks = [...task.subtasks]
        newSubtasks.splice(index, 1)
        newSubtasks.splice(index, 0, newSubtask)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)
    }

    const handleSubtaskBlur = (event, index) => {
        const newTitle = event.target.value

        const newSubtasks = [...task.subtasks]
        const newSubtask = {
            ...newSubtasks[index],
            title: newTitle,
        }

        newSubtasks.splice(index, 1, newSubtask)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)

        setShowSubtasksDeleteButton(false)
    }

    const handleDeleteSubtask = (index) => {
        const newSubtasks = [...task.subtasks]

        newSubtasks.splice(index, 1)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)

        setShowSubtasksDeleteButton(false)
    }

    const handleAddSubtask = () => {
        const newSubtasks = [...task.subtasks]
        const newSubtask = {
            title: "",
            done: false
        }

        newSubtasks.push(newSubtask)

        const newTask = {
            ...task,
            subtasks: newSubtasks
        }

        handleUpdateTask(newTask)
    }

    const handleTitleChange = (event) => {
        const newTitle = event.target.value
        setTitle(newTitle)
    }

    const handleTitleBlur = (event) => {
        const newTitle = event.target.value
        setTitle(newTitle)

        const newTask = {
            ...task,
            title: newTitle,
        }

        handleUpdateTask(newTask)
    }

    const handleCompanyChange = (event) => {
        const newCompany = event.target.value
        setCompany(newCompany)
    }

    const handleCompanyBlur = (event) => {
        const newCompany = event.target.value
        setCompany(newCompany)

        const newTask = {
            ...task,
            company: newCompany,
        }

        handleUpdateTask(newTask)
    }

    const handleDeleteTask = async (task) => {

        await api.deleteOrder(task.id, task)
            .then(res => {
                const deletedTaskId = res.data.id
                const newTasksIds = [...data.columns[task.status].tasksIds]
                const deletedTaskIndex = newTasksIds.findIndex(el => el === deletedTaskId)
                newTasksIds.splice(deletedTaskIndex, 1)

                return newTasksIds
            })
            .then(res => {
                const newColumn = {
                    ...data.columns[task.status],
                    tasksIds: res,
                }

                handleUpdateColumn(newColumn)
            })
    }

    const handleUpdateColumn = async (column) => {
        await api.updateOrderColumn(column._id, column)
            .then(res => sendMessageToHost({ code: 102 }))
    }

    const populateSubtasks = () => {
        const subtasks = [...task.subtasks]

        subtasks.forEach((subtask, index) => {
            document.getElementById('subtask-' + (isReadOnly ? 't' : 'f') + task.id + '-' + index).value = subtask.title
        });
    }

    useEffect(() => {
        populateSubtasks()
    })

    const handleAssignedToChanged = (id, index) => {
        const userIndex = users.findIndex(el => el._id === id)

        var newUserIndex = 0
        if (userIndex + 1 < users.length) {
            newUserIndex = userIndex + 1
        }

        const newUser = users[newUserIndex]._id
        const newAssignedTo = [...task.assignedTo]
        newAssignedTo.splice(index, 0)
        newAssignedTo.splice(index, 1, newUser)

        const newTask = {
            ...task,
            assignedTo: newAssignedTo
        }

        handleUpdateTask(newTask)
    }

    const handleAddAssignedTo = () => {
        const newAssignedTo = [...task.assignedTo]

        if (task.assignedTo.length < users.length) {
            users.every(user => {
                if (!task.assignedTo.includes(user._id)) {
                    newAssignedTo.push(user._id)
                    return false
                }
                return true
            });

            const newTask = {
                ...task,
                assignedTo: newAssignedTo
            }

            handleUpdateTask(newTask)
        }
    }

    const handleProjectChanged = () => {
        const projectIndex = projects.findIndex(el => el._id === task.project)

        var newProjectIndex = 0
        if (projectIndex + 1 < projects.length) {
            newProjectIndex = projectIndex + 1
        }

        const newProject = projects[newProjectIndex]._id

        const newTask = {
            ...task,
            project: newProject
        }

        handleUpdateTask(newTask)
    }

    return (
        <Draggable
            draggableId={task.id}
            index={index}
            isDragDisabled={authState && authState.userInfo &&
                (authState.userInfo._id !== task.assignedTo[0] ? authState.userInfo.role !== 'admin' : false)}
        >
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    <Row>
                        <Column noPadding sm={12} md={12} lg={12}>
                            <Header color={projects && projects.find(el => el._id === task.project).color}>
                                <span onClick={handleProjectChanged}>{projects && projects.find(el => el._id === task.project).title}</span>
                                <AcitonButtonsContainer>
                                    {/* <Icon onClick={handleAddSubtask}><Add color='white' /></Icon> */}
                                    <Icon onClick={() => { handleDeleteTask(task) }}><Trash color='white' /></Icon>
                                </AcitonButtonsContainer>
                            </Header>
                        </Column>
                        <Column flex sm={12} md={12} lg={12}>
                            <Title value={title} onChange={handleTitleChange} onBlur={handleTitleBlur}></Title>
                        </Column>
                        {/* <Column sm={12} md={12} lg={12}>
                            <SubTasksContainer>
                                {task.subtasks.map((subtask, index) => {
                                    return (
                                        <SubTask key={index}>
                                            <SubTaskCheckbox
                                                type='checkbox'
                                                checked={subtask.done}
                                                onChange={() => handleSubtaskCheckChanged(subtask, index)}
                                            />
                                            <SubTaskText
                                                id={'subtask-' + (isReadOnly ? 't' : 'f') + task.id + '-' + index}
                                                onFocus={(event) => {
                                                    event.target.value = subtask.title
                                                    setShowSubtasksDeleteButton(true)
                                                }}
                                                onBlur={(event) => handleSubtaskBlur(event, index)}
                                            />
                                            <Icon hidden={!showSubtasksDeleteButton} onClick={() => handleDeleteSubtask(index)}><Close /></Icon>
                                        </SubTask>
                                    )
                                })}
                            </SubTasksContainer>
                        </Column> */}
                        <Column sm={12} md={12} lg={12}>
                            <SubContainer>
                                <Company value={company} onChange={handleCompanyChange} onBlur={handleCompanyBlur}></Company>
                            </SubContainer>
                        </Column>
                        <Column sm={12} md={12} lg={12}>
                            <SubContainer right>
                                <Button noPadding color='transparent' textColor={task.agency && (
                                    task.agency === 'FAPESP' ? 'steelblue' :
                                        task.agency === 'FAFQ' ? 'rebeccapurple' : 'gray'
                                )}
                                    onClick={() => handleAgencyChange(task)}
                                >
                                    {task.agency}
                                </Button>
                            </SubContainer>
                        </Column>
                    </Row>
                </Container>
            )}
        </Draggable>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    /* padding: 1rem; */
    border-radius: 0.25rem;

    background-color: ${({ isDragging }) => isDragging ? 'lightsteelblue' : 'white'};
`
const Icon = styled.div`
    width:  1.25rem;
    height: 1.25rem;
    margin-left: 1rem;

    ${StyledIconBase} {
        :hover {
            opacity: 0.7;
        }
    }

    ${({ hidden }) => hidden && `display: none;`}
`
const Header = styled.div`
    padding: 0.5rem 1rem;
    background-color: ${({ color }) => color ? color : 'grey'};

    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: lightgrey;
        cursor: pointer;
        text-transform: uppercase;
    }
`
const Title = styled.input`
    margin: 0.25rem;
    display: flex;
    flex-grow: 1;
    font-size: 0.8rem;
    font-weight: 300;
    outline: none;

    border: 0rem solid #000;
`
const Company = styled.input`
    display: flex;
    margin: 0.25rem;
    font-size: 0.8rem;
    font-weight: 700;
    outline: none;
    letter-spacing: 0.1rem;

    border: 0rem solid #000;
`
const TaskList = styled.div`

`
const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${({ right }) => right ? 'flex-end' : 'space-between'};
    align-items: center;
    // margin: 0.25rem 0.5rem;
`
const InitialsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 3;

    ${Icon} {
        display: none;
    }

    :hover {
        ${Icon} {
            display: block;
        }
    }
`
const Initials = styled.div`
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ color }) => color ? color : 'lightgrey'};

    /* cursor: pointer; */
`
const SubTasksContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SubTask = styled.div`
    margin: 0rem 1rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const SubTaskCheckbox = styled.input`
    width:  1.3rem;
    height: 1.3rem;
`
const SubTaskText = styled(Title)`
    margin-left: 0.25rem;
    font-size: 0.9rem;
    margin: 0.5rem 0rem;
`
const AcitonButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export default OrderTask